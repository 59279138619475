<template>
  <label :for="radioId" class="radio-input" :class="{checked: radioValue === this.value}">
    <input :id="radioId" type="radio" :name="name" :value="radioValue" v-model="check" @change="update">
    <div>
      <div></div>
    </div>
    <span>{{radioValue}}</span>
  </label>
</template>

<script>
export default {
  name: 'RadioInput',
  props: {
    value: {},
    radioId: {
      type: String
    },
    radioValue: {
      type: String
    },
    name: {
      type: String
    }
  },
  watch: {
    value() {
      this.check = this.value;
    }
  },
  data() {
    return {
      check: this.value
    }
  },
  methods: {
    update() {
      this.$emit('input', this.check);
    }
  }
}
</script>

<style lang="scss" scoped>

.radio-input {
  cursor: pointer;
  position: relative;
  display: grid;
  width: max-content;
  grid-template-columns: 19px 1fr;
  grid-column-gap: 8px;
  align-items: center;
  input {
    position: absolute;
    margin: 0;
    opacity: 0;
  }
  > div {
    justify-self: center;
    position: relatives;
    height: 19px;
    width: 19px;
    background-color: $primary-02;
    border: 1px solid $neutral-07;
    border-radius: 50%;
  }
  &.checked {
    div {
      margin: 0;
      padding: 0;
    }
    > div {
      border: 1px solid $secondary-03;
      display: grid;
      align-items: center;
      justify-items: center;

      div {
        position: absolute;
        height: 12px;
        width: 12px;
        background-color: $secondary-03;
        border-radius: 50%;
        margin: 3.5px;
      }
    }
  }
}
</style>
