<template>
  <div class="donut-chart" v-show="showDonut" :class="{small: small}">
    <div class="donut">
      <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path v-for="(value, index) in donutValues" :key="index" :d="value.d" fill="none" :stroke="value.color" stroke-linecap="round" stroke-width="10"/>
      </svg>
      <div>
        <span>{{donutTitle}}</span>
        <h2>{{totalApy | percentage}}</h2>
      </div>
    </div>
    <ul>
      <li v-for="(percentage, index) in percentages" :key="index">
        <div class="dot" :style="{'background-color': percentage.color}"></div>
        <h4>{{percentage.number | percentage}}</h4>
        <h4>{{percentage.text}}</h4>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'DonutChart',
  props: {
    percentages: {
      type: Array,
    },
    donutTitle: {
      type: String
    },
    small: {
      type: Boolean
    }
  },
  filters: {
    percentage(value) {
      return `${Number(Math.round(value+'e' + 2) + 'e-' + 2)}%`;
    }
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {
    showDonut() {
      return this.percentages &&  this.percentages.length > 0;
    },
    totalApy() {
      return this.showDonut ? this.percentages.map(p => p.number).reduce((a, b) => b + a) : 0;
    },
    donutValues() {
      let donutValues = [];
 
      for (let index = 0; index < this.percentages.length; index++) {
        let value = { start: 0, end: 0, d: "", color: ""};

        if (index != 0) {
          let lastIndex = index - 1;
          value.start = donutValues[lastIndex].end;
          value.end = donutValues[lastIndex].end;
        }

        value.end += this.getPercentage(this.percentages[index].number, this.totalApy);

        value.d = this.getDraw(value.start, value.end);
        value.color = this.percentages[index].color;

        donutValues.push(value);
      }

      return donutValues;
    }
  },
  methods: {
    getDraw(start, end) {
      return this.describeArc(90, 90, 80, start, end)
    },
    getPercentage(x, total) {
      return x * 360 / total;
    },
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      };
    },
    describeArc(x, y, radius, startAngle, endAngle){

        var start = this.polarToCartesian(x, y, radius, endAngle);
        var end = this.polarToCartesian(x, y, radius, startAngle);

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
            "M", start.x, start.y, 
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;       
    }
  }
}
</script>

<style lang="scss" scoped>

.donut-chart {
  display: grid;
  align-items: center;
  @include respond-to("medium and up") {
    grid-template-columns: .8fr 1fr;
  }
  &.small {
    grid-template-columns: 1fr;
  }
  .donut {
    justify-self: center;
    width: min-content;
    position: relative;
    div {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: grid;
      grid-auto-rows: min-content;
      align-items: center;
      justify-items: center;
      span {
        line-height: 1.5rem;
      }
    }
  }
  ul {
    display: grid;
    text-align: start;
    list-style-type: inherit;
    li {
      padding: 1rem;
      display: grid;
      grid-template-columns: auto auto 1fr;
      grid-column-gap: 1rem;
      align-items: center;
      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      h4 {
        line-height: 22px;
      }
      :last-child {
        color: $primary-09;
      }
    }
  }
}
</style>
