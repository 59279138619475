<template>
  <div class="withdraw">

    <loading v-if="isLoading" :text="loaderText" />

    <div v-else>
      <div class="text-two-columns">
        <h4>Current Balance</h4>
        <span>
          <balance-displayer contract-id="ust-staking-pool" currency="UST">Show Balance</balance-displayer>
        </span>
      </div>

      <validation-observer v-slot="{ validate, invalid }">
        <form @submit.prevent="validate().then(submit)" autocomplete="off">

          <validation-provider tag="fieldset" :rules="{required: true, max_value: poolBalance, min_value: 1}">
            <currency-input v-model="currentAmount" currency="UST" :maxValue="poolBalance"/>
          </validation-provider>

          <button class="primary" :disabled="invalid">Withdraw</button>

        </form>
      </validation-observer>

    </div>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import BalanceDisplayer from './BalanceDisplayer.vue';
import CurrencyInput from './CurrencyInput.vue'
import Loading from './Loading.vue'

import { coinConvert } from '@stakeordie/griptape.js';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BalanceDisplayer,
    CurrencyInput,
    Loading,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      isLoading: false,
      currentAmount: null,
      token: null
    }
  },

  methods: {
    ...mapActions({
      withdraw: 'ustStakingPool/withdraw'
    }),

    async submit() {
      if (!this.currentAmount) return;
      const amount = coinConvert(this.currentAmount, 18, 'machine');
      this.isLoading = true;
      await this.withdraw(amount);
      this.currentAmount = null;
      this.isLoading = false;
    }
  },

  computed: {
    loaderText() {
      return `Withdraw ${this.currentAmount} UST SP...`
    },

    ...mapState('ustStakingPool', {
      ustStakingPoolBalance: state => state.balance
    }),

    poolBalance() {
      return this.ustStakingPoolBalance ? Number(coinConvert(this.ustStakingPoolBalance, 18, 'human')) : 0;
    }
  }
}
</script>

<style lang="scss" scoped>

.withdraw {
  color: $primary-02;
  div {
    display: grid;
    grid-row-gap: 27px;
    text-align: center;
    &:last-child {
      border-radius: 0 0 1rem 1rem;
    }
    .text-two-columns {
      display: grid;
      grid-template-columns: max-content 1fr;
      color: $primary-09;
      :first-child {
        justify-self: start;
      }
      :last-child {
        justify-self: end;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
