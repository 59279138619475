<template>
  <div class="custom-table">
    <table>
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index">
            <h4>{{header}}</h4>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td v-for="(key, indexColumn) in Object.keys(row)" :key="indexColumn">
            <h4>{{row[key]}}</h4>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'CustomTable',
    props: {
      rows: {
        type: Array
      },
      headers: {
        type: Array
      }
    }
  };
</script>

<style lang="scss" scoped>

  .custom-table {
    display: grid;
    table {

      th {
        h4 {
          color: $neutral-06;
        }
      }

      thead, tbody {

        tr {
          > {
            :first-child {
              text-align: start;
            }
            :not(:first-child) {
              text-align: end;
            }

          }

          td, th {
            padding: 5px 0;
            > * {
              line-height: 1.5rem;
            }
            &:not(:last-child) {
              padding-right: 1rem;
            }
          }

          &:last-child {
            th {
              padding-top: 0;
            }
            td {
              padding-bottom: 0;
            }
          }

        }
      }
    }
  }
</style>
