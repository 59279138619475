<template>
  <div class="code-input">
    <div class="one-time-code-input__content" id="validate-code-inputs">
      <input type="text" :id="`code-input-${digit}`"
        inputmode="numeric"
        v-for="(digit, index) in digits"
        :key="index" placeholder="0" maxlength="1"
        v-model="values[digit]"
        @keypress="keypress($event, digit)"
        @keydown="keydown($event, digit)"
        :oninput="oninput(digit)"
        autocomplete="off"
        @change="change"
        @paste="onPaste"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CodeInput',
  props: {
    value: {},
    digits: {
      type: Number
    }
  },
  data() {
    return {
      currencyCode: this.value,
      values: {}
    }
  },
  mounted() {
    for (let index = 1; index <= this.digits; index++) {
      this.$set(this.values, `${index}`, null);
    }
    document.getElementById(`code-input-1`).focus();
  },
  methods: {
    change() {
      let keys = Object.keys(this.values);
      let value = keys.map(k => this.values[k]).join("");
      if (value.length == this.digits) {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
    oninput(index) {
      let value = this.values[index];
      if (value) {
          this.values[index] = value.replace(/\D/g,'').replace("/\\./g","");
      }
    },
    keydown(e, currentInput) {

      let nextInput = currentInput + 1;

      if (this.isNotNumber(e.key)) {

        if (e.keyCode == 39 && currentInput < this.digits) {
          document.getElementById(`code-input-${nextInput}`).focus();
        } else if (e.keyCode == 37 && currentInput > 1) {
          document.getElementById(`code-input-${currentInput - 1}`).focus();
        } else if (e.keyCode == 8 && currentInput > 1 && !this.values[currentInput]) {
          this.values[currentInput] = null;
          document.getElementById(`code-input-${currentInput - 1}`).focus();
        }

      } else {

        if (this.values[currentInput]) {
          this.values[currentInput] = null;
        }

        setTimeout(() => {
          if (currentInput < this.digits) {
            document.getElementById(`code-input-${nextInput}`).focus();
          } else {
            let el = document.querySelector( ':focus' );
            if( el ) el.blur();
          }
        }, 50);
      }

    },
    keypress(e, currentInput) {
      if (!this.isNotNumber(e.key)) {
        let value = e.target.value;
        this.values[currentInput] = value;      
      }
    },
    isNotNumber(value) {
      return isNaN(Number(value));
    },
    onPaste(e) {
      const text = (e.originalEvent || e).clipboardData.getData('text/plain').trim();

      if (!this.isNotNumber(text)) {
        const length = text.length < this.digits ? text.length : this.digits;

        for (let index = 0; index < length; index++) {
          this.values[index + 1] = text.charAt(index);
        }

        let el = document.querySelector( ':focus' );
        if( el ) el.blur();

        this.change();
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.code-input {
  position: relative;
  display: grid;
  justify-items: center;
  grid-column-gap: 8px;
  background-color: $primary-04;
  border-radius: 4px;
  width: 100%;

  &__content {
    display: grid;
    grid-auto-flow: column;
  }

  input {
    width: 37px;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    background-color: $primary-04;
    color: $neutral-white;
    border: 0;
    align-self: center;
    border-radius: 4px;
    line-height: 52.8px;
    font-size: 44px;
    padding: 1rem 0;
  }


  //Remove arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

}
</style>
