const ONE_TIME_CODE = "ONE_TIME_CODE";

export default {
  namespaced: true,

  state: {
    hasSentAnEmail: false,
    switchRequestAccess: false,
    switchLaunchApp: false,
    oneTimecode: null
  },

  getters: {
    oneTimeCode: (state) => {
      return localStorage.getItem(ONE_TIME_CODE) || state.oneTimecode;
    },
    hasSentAnEmail: (state) => {
      return state.hasSentAnEmail;
    }
  },

  mutations: {
    updateSwitchRequestAccess(state) {
      state.switchRequestAccess = !state.switchRequestAccess; 
    },
    updateSwitchLaunchApp(state) {
      state.switchLaunchApp = !state.switchLaunchApp;
    },
    saveOneTimecode(state, code) {
      state.oneTimecode = code;
    },
    updateHasSentAnEmail(state, value) {
      state.hasSentAnEmail = value;
    }
  },

  actions: {
    hasSentAnEmail({commit}) {
      commit('updateHasSentAnEmail', true);
    },
    hasNotSentAnEmail({commit}) {
      commit('updateHasSentAnEmail', false);
    },
    updateSwitchRequestAccess({commit}) {
      commit('updateSwitchRequestAccess');
    },
    updateSwitchLaunchApp({commit}) {
      commit('updateSwitchLaunchApp');
    },
    saveOneTimeCode({commit}, code) {
      localStorage.setItem(ONE_TIME_CODE, code);
      commit('saveOneTimecode', code);
    }
  }
};
