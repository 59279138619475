<template>
  <div class="claim">
    <loading :text="loaderText" v-if="isLoading"/>
    <div v-else>
      <div>
        <span>Rewards</span>
        <balance-displayer v-if="!rewards" contract-id="ust-staking-pool" currency="UST SP">Show Rewards</balance-displayer>
        <h3 v-else>{{ `${rewards} SEFI` }}</h3>
      </div>
      <button class="primary" @click="makeClaim" :disabled="!rewards || rewards == 0">Claim</button>
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue';
import BalanceDisplayer from './BalanceDisplayer.vue';
import { mapActions, mapState } from 'vuex';
import { coinConvert } from '@stakeordie/griptape.js';

export default {
  components: { Loading, BalanceDisplayer },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    // Mapping `claim` store actions.
    ...mapActions('ustStakingPool', ['redeem']),

    async makeClaim() {
      this.isLoading = true;

      await this.redeem();

      this.isLoading = false;
    }
  },

  computed: {
    ...mapState('ustStakingPool', {
      rawRewards: state => state.rewards
    }),

    loaderText() {
      return `Claiming ${this.rewards} SEFI...`
    },

    rewards() {
      return coinConvert(this.rawRewards, 6, 'human', 2);
    }
  }
}
</script>

<style lang="scss" scoped>
.claim {
  color: $primary-02;
  > div {
    display: grid;
    grid-row-gap: 27px;
    > div {
      display: grid;
      grid-row-gap: 4px;
      text-align: start;
    }
    &:last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }
}
</style>
