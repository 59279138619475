import {
  createContract,
  extendContract,
  snip20Def
} from '@stakeordie/griptape.js';

const {
  // UST Staking Pool contract address.
  VUE_APP_UST_STAKING_POOL_ADDRESS: ustStakingPoolContractAddress,

  // sUST SNIP-20 token address.
  VUE_APP_SUST_ADDRESS: sustContractAddress
} = process.env;

// `{"deposit":{}}` base64 encoded.
const msg = 'eyJkZXBvc2l0Ijp7fX0=';

const sustDef = {
  messages: {
    deposit(_, amount) {
      const fees = 225000;
      const recipient = ustStakingPoolContractAddress;
      const handleMsg = {
        send: {
          amount,
          recipient,
          msg
        }
      };
      return { handleMsg, fees };
    }
  }
};

const def = extendContract(snip20Def, sustDef);

export const sust = createContract({
  id: 'sust',
  at: sustContractAddress,
  definition: def
});
