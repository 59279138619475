<template>
  <modal ref="modal" @close="closeModal()" width="431px">
    <div class="verification-code-modal">
      <verification-code-form v-if="hasSentAnEmail"></verification-code-form>
      <generate-code-form v-else @onFinish="closeModal"></generate-code-form>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import GenerateCodeForm from '../forms/GenerateCodeForm'
import VerificationCodeForm from '../forms/VerificationCodeForm'
import { mapState } from 'vuex';

export default {
  name: 'GenerateCodeModal',
  props: {},
  data() {
    return {
      email: null,
      isLoading: false
    }
  },
  components: { Modal, GenerateCodeForm, VerificationCodeForm },
  computed: {

    ...mapState('oneTimeStore', {
      hasSentAnEmail: state => state.hasSentAnEmail
    })

  },
  methods: {
    openModal() {
      this.$refs.modal && this.$refs.modal.open();
    },

    closeModal() {
      this.$refs.modal && this.$refs.modal.close();
    },
  }
}
</script>

<style lang="scss" scoped>

.verification-code-modal {
  background-color: $primary-02;
  padding: 1.5rem;

  & > div:first-child {
    @include respond-to("large and up") {
      padding: 1.5rem;
    }
  }

  .heading {
    text-align: center;
  }
  &__form {
    display: grid;
    grid-row-gap: 26px;

  }

}

</style>
