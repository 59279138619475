<template>
  <modal ref="modal" @close="closeModal()" width="431px">
    <div class="keplr-info-modal">
      <h3>Important <a href="" @click.prevent="closeModal"><img src="@/assets/close--white.svg" alt="close icon"></a></h3>

      <h4>To use Duplex you first need to have UST <br class="desktop"> (ERC-20) bridged to Secret Network</h4>

      <ul>
        <li>
          <h4>1</h4>
          <span>Download
            <a href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en" target="_blank" rel="noopener noreferrer">
              <img src="@/assets/keplr.svg" alt="keplr logo">
            </a>
          </span>
        </li>
        <li>
          <h4>2</h4>
          <span>Bridge your <a href="https://ust.scrt.network" target="_blank" rel="noopener noreferrer">assets</a></span>
        </li>
        <li>
          <h4>3</h4>
          <span>Close this window and deposit to start earning yield</span>
        </li>
        <li class="line"></li>
      </ul>

      <form action="">
        <div class="simple-form-content">
          <div class="inputs">
            <label class="checkbox">
              <input type="checkbox" name="dontShowKeplrModalAgain" v-model="dontShowKeplrModalAgain" :value="true">
              <span></span>
              <div>I know, I know... Don't show me this again</div>
            </label>
          </div>
        </div>
      </form>

      <a href="" class="button primary" @click.prevent="ok">OK</a>

    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import { mapActions } from 'vuex';

export default {
  name: 'KeplrInfoModal',
  props: {},
  components: { Modal },
  data() {
    return {
      dontShowKeplrModalAgain: false
    }
  },
  methods: {
    ...mapActions({ 
      dontShowModalAgain: 'keplrStore/dontShowModalAgain'
    }),
    ok() {
      if (this.dontShowKeplrModalAgain) {
        this.dontShowModalAgain();
      }
      this.closeModal();
    },
    openModal() {
      this.$refs.modal && this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal && this.$refs.modal.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.keplr-info-modal {
  display: grid;
  grid-row-gap: 26px;
  padding: 24px;
  background-color: $primary-02;
  h3, > h4 {
    text-align: center;
  }
  h3 {
    position: relative;
    a {
      position: absolute;
      right: 0;
    }
  }
  > h4 {
    color: $primary-08;
    font-weight: 600;
  }
  ul {
    position: relative;
    display: grid;
    grid-row-gap: 1rem;
    li {
      z-index: 5;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 1rem;
      align-items: center;
      h4 {
        background-color: $primary-02;
        border: 1px solid $primary-08;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: grid;
        align-items: center;
        justify-items: center;
        margin: 0 !important;
      }
      span {
        img {
          display: inline-block;
          margin: -3px .25rem;
          vertical-align: center;
        }
        a {
          text-decoration: none;
          color: $secondary-03;
        }
      }
    }
    .line {
      z-index: 1;
      position: absolute;
      width: 1px;
      background-color: $primary-08;
      height: calc(100% - 40px);
      top: 20px;
      left: 20px;
    }
  }
  @include respond-to("small and down") {
    .desktop { display: none }
  }
}
</style>
