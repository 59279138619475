<template>
  <base-layout class="group" basic>
    <div class="home">
      <div class="home__content">
        <h1><span>Duplex.</span> World-class performance on stable assets.</h1>
        <p>Duplex captures the full potential of stable coins by introducing a
        twofold strategy that generates game-changing yield.</p>
        <meter-progress currency="UST" class="yellow"></meter-progress>
        <div class="actions">
          <button class="primary" @click.prevent="openVerificationCodeModal">Launch App</button>
          <button class="secondary" @click.prevent="openRequestAccessModal">Request Access</button>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import MeterProgress from '../components/MeterProgress';
import { mapActions  } from 'vuex';

export default {
  name: 'Home',
  components: { MeterProgress },
  methods: {
    ...mapActions('oneTimeStore', ['updateSwitchRequestAccess', 'updateSwitchLaunchApp']),

    openRequestAccessModal() {
      this.updateSwitchRequestAccess();
    },

    openVerificationCodeModal() {
      this.updateSwitchLaunchApp();
    }
  }
}
</script>

<style lang="scss" scoped>

.home {
  display: grid;
  justify-items: center;
  align-items: center;
  h1 span {
    color: $secondary-03;

    @include respond-to("medium and down") {
      display: block;
      margin-bottom: 16px;
    }
  }
  &__content {
    display: grid;
    grid-template-rows: min-content;
    grid-row-gap: 26px;
    text-align: center;
    justify-items: center;

    h1 {
      @include respond-to("large and up") {
        max-width: 824px;
      }
    }
    p {
      max-width: 800px;
      font-size: 1.5rem;
      line-height: 31.2px;
    }
    .actions {
      @include respond-to("medium and down") {
        width: 100%;
      }
      @include respond-to("large and up") {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 16px;
      }

      button, .button {
        &:first-child {
          @include respond-to("medium and down") {
            margin-bottom: 16px;
          }
        }

        @include respond-to("medium and down") {
          width: 100%;
        }

        @include respond-to("large and up") {
          width: 240px;
        }
      }
    }
    @include respond-to("medium and up") {
      .actions {
        grid-auto-flow: column;
      }
      &__content {
        p {
          max-width: 712px;
        }
      }
    }
  }
}
</style>
