<template>
  <base-layout class="group">
    <div class="faq-page">
      <h3><span>Duplex.</span> World-class performance on stable assets.</h3>
      <faq-list :faqs="faqs"></faq-list>
    </div>
  </base-layout>
</template>

<script>
import FaqList from '@/components/FaqList';

const faqs = [
  {
    question: "What is Yieldsquared?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie tincidunt cursus id eget quis. Morbi et quam in commodo at aliquam. Dui neque ipsum eu, vestibulum enim rhoncus amet morbi. Hac quam mattis in donec mus congue."
  },
  {
    question: "How does it work?",
    answer:  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie tincidunt cursus id eget quis. Morbi et quam in commodo at aliquam. Dui neque ipsum eu, vestibulum enim rhoncus amet morbi. Hac quam mattis in donec mus congue."
  },
  {
    question: "What is APY?",
    answer:  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie tincidunt cursus id eget quis. Morbi et quam in commodo at aliquam. Dui neque ipsum eu, vestibulum enim rhoncus amet morbi. Hac quam mattis in donec mus congue."
  },
  {
    question: "How do you calculate APY?",
    answer:  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie tincidunt cursus id eget quis. Morbi et quam in commodo at aliquam. Dui neque ipsum eu, vestibulum enim rhoncus amet morbi. Hac quam mattis in donec mus congue."
  },
  {
    question: "Is it free to use Yieldsquared?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie tincidunt cursus id eget quis. Morbi et quam in commodo at aliquam. Dui neque ipsum eu, vestibulum enim rhoncus amet morbi. Hac quam mattis in donec mus congue."
  }
];

export default {
  name: 'FAQPage',
  components: { FaqList },
  computed: {
    faqs() {
      return faqs;
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-page {
  @include respond-to("large and up") {
    padding-top: 56px;
  }

  h3 {
    text-align: center;
    margin: 0 0 40px 0;
    span {
      color: $secondary-03;
    }
  }
}
</style>
