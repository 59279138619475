<template>
    <section v-if="isOpen" class="modal" ref="modal" :class="{ 'open': isOpen }" :key="key">
        <div class="modal__overlay" @click="!disableOverlayEvents && closeOnOverlay()"></div>
        <div class="modal__content" :style="{width: width}">
            <slot></slot>
        </div>
    </section>
</template>

<script>
const modal = {
    name: 'modal',
    props: {
        onClose: {
            type: Function
        },
        width: {
            type: String
        },
        disableOverlayEvents: {
            type: Boolean
        }
    },
    data() {
        return {
            key: null,
            isOpen: false
        }
    },
    methods: {
        open() {
            this.key = Math.floor(Math.random() * 2000) + 1;
            setTimeout(() => {
                this.isOpen = true;
            }, 100);

            document.addEventListener("keydown", this.keydown);

        },
        close() {
            if (!this.closeOnOverlay) window.scrollTo(0, 0);
            this.isOpen = false;
            this.onClose && this.onClose();

            document.removeEventListener("keydown", this.keydown);

        },
        closeOnOverlay() {
            this.$emit('close');
        },
        keydown(event) {
            if (event.keyCode == 27) {
                this.close();
            }
        }
    }
};

export default modal;
</script>
<style scoped lang="scss">

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    display: grid;
    align-items: center;
    justify-items: center;

    &.open {
        opacity: 1;
        transition: opacity 0.3s ease-in;
        z-index: 1000;
    }

    .modal__overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba($neutral-01, 0.8);
        width: 100%;
        height: 100%;
    }

    &__content {
        margin: 0 auto;
        max-height: 95%;
        max-width: 95%;
        right: 0;
        left: 0;
        position: absolute;
        @include respond-to("medium and down") {
            overflow: hidden;
            overflow-y: scroll;
        }
        > div {
            border-radius: 1rem;
            -moz-border-radius: 1rem;
            -webkit-border-radius: 1rem;
        }
    }
}
</style>