<template>
  <span>
    <transition name="fade">
      <span v-if="isLoading">...</span>

      <span v-else>
        <span v-if="noViewingKey">
          <a href="#" @click.prevent="showBalance"><slot></slot></a>
        </span>

        <span v-else-if="balance">
          {{ `${coinConvert(balance, 18, 'human', 2)} ${currency ? currency : ''}` }}
        </span>
      </span>
    </transition>
  </span>
</template>

<script>
import {
  getKeplr,
  viewingKeyManager,
  coinConvert,
  getChainId,
  onAccountAvailable,
  bootstrap
} from '@stakeordie/griptape.js';
import { mapActions, mapState } from 'vuex';
import { sust, ustStakingPool } from '@/contracts';

export default {
  props: {
    contractId: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      noViewingKey: true,
      isLoading: true,
    }
  },

  mounted() {
    onAccountAvailable(() => {
      this.bootstrap();
    });
  },

  methods: {
    coinConvert,

    ...mapActions({
      updateSustBalance: 'sust/updateBalance',
      updateUstStakingPoolBalance: 'ustStakingPool/updateBalance',
      updateRewards: 'ustStakingPool/updateRewards'
    }),

    async bootstrap() {
      if (this.hasViewingKey()) {
        await this.fetchBalance();
        this.noViewingKey = false;
      } else {
        await this.getViewingKeys();
      }
      this.isLoading = false;
    },

    async fetchBalance() {
      if (this.contractId === 'sust') {
        await this.updateSustBalance();
      } else if (this.contractId === 'ust-staking-pool') {
        await this.updateUstStakingPoolBalance();
      }
    },

    async getViewingKeys() {
      const chainId = await getChainId();
      const keplr = await getKeplr();

      const contract = this.getContract();
      if (!contract) throw new Error(`No contract found for id ${this.contractId}`);
      const { at: contractAddress } = contract;

      try {
        const key = await keplr.getSecret20ViewingKey(chainId, contractAddress);
        if (this.contractId === 'sust') {
          viewingKeyManager.add(sust, key);
        } else if (this.contractId === 'ust-staking-pool') {
          viewingKeyManager.add(ustStakingPool, key);
        }
        // Fetch balances.
        await this.fetchBalance();

        // Update rewards right after setting this viewing key.
        await this.updateRewards();

        this.noViewingKey = false;
      } catch (e) {
        // ignore
      }
    },

    async showBalance() {
      await bootstrap();

      const chainId = await getChainId();
      const keplr = await getKeplr();

      const contract = this.getContract();
      if (!contract) throw new Error(`No contract found for id ${this.contractId}`);
      const { at: contractAddress } = contract;

      let key;

      try {
        key = await keplr.getSecret20ViewingKey(chainId, contractAddress);
      } catch (e) {
        // ignore
      }

      if (!key) {
        await keplr.suggestToken(chainId, contractAddress);
      }

      key = await keplr.getSecret20ViewingKey(chainId, contractAddress);

      if (this.contractId === 'sust') {
        viewingKeyManager.add(sust, key);
      } else if (this.contractId === 'ust-staking-pool') {
        viewingKeyManager.add(ustStakingPool, key);
      }

      // Fetch balances.
      await this.fetchBalance();

      // Update rewards right after setting this viewing key.
      await this.updateRewards();

      this.noViewingKey = false;
    },

    hasViewingKey() {
      const key = viewingKeyManager.get(this.contractId);
      return typeof key !== 'undefined';
    },

    getContract() {
      if (this.contractId === 'sust') {
        return sust;
      } else if (this.contractId === 'ust-staking-pool') {
        return ustStakingPool;
      }
      return;
    }
  },

  computed: {
    ...mapState('sust', {
      sustBalance: state => state.balance
    }),
    ...mapState('ustStakingPool', {
      ustStakingPoolBalance: state => state.balance
    }),

    balance() {
      if (this.contractId === 'sust') {
        return this.sustBalance;
      } else if (this.contractId === 'ust-staking-pool') {
        return this.ustStakingPoolBalance;
      }
      return '0';
    }
  }
}
</script>
