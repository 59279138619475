<template>
  <div id="app">
    <message-center/>
    <router-view/>
    <keplr-info-modal ref="modal"/>
  </div>
</template>

<script>
import MessageCenter from '@/components/MessageCenter';
import KeplrInfoModal from '@/components/modal/KeplrInfoModal';
import { onAccountAvailable, getAddress } from '@stakeordie/griptape.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    MessageCenter,
    KeplrInfoModal
  },

  watch: {
    'switchKeplrModal'() {
      this.openModal();
    }
  },

  computed: {
    ...mapGetters({
      dontShowModalAgain: 'keplrStore/dontShowModalAgain'
    }),

    switchKeplrModal() {
      return this.$store.state.keplrStore.switchKeplrModal;
    }
  },

  mounted() {
    onAccountAvailable(() => {
      const address = getAddress();
      if (!address) {
        this.openModal();
      }
    });
  },

  methods: {
    openModal() {
      if (!this.dontShowModalAgain) {
        this.$refs.modal.openModal();
      }
    }
  }
};
</script>

<style>
#app {
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
