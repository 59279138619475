<template>
  <div class="loading">
    <img src="../assets/loading.svg" alt="loading icon">
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  display: grid;
  justify-self: center;
  justify-items: center;
  grid-row-gap: 1rem;
  &.small {
    img {
      height: 2rem;
      width: 2rem;
    }
  }
  img {
    animation: rotate-animation 1.5s infinite linear;
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
