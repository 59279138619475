<template>
  <nav class="navigation-bar">
    <ul>
      <li v-for="(route, index) in routes" :key="index" :class="{current: route.name == $route.name, hide: route.authRequire && !oneTimeCode}">
        <a v-if="route.external"
          :href="route.name"
          class="external"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{{ route.title }}</span>
          <img src="../assets/external-link.svg" alt="bridge link">
        </a>
        <router-link v-else :to="{ name: route.name }">
          {{route.title}}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationBar',
  props: {
    routes: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      oneTimeCode: 'oneTimeStore/oneTimeCode'
    }),
  },
}
</script>

<style lang="scss" scoped>

.navigation-bar {
  display: grid;
  justify-items: center;
  ul {
    display: grid;
    grid-auto-flow: column;
    width: min-content;
    li {
      padding: 23px 16px;
      cursor: pointer;
      &.hide {
        display: none;
      }
      &.current {
        font-weight: 600;
      }
    }
    a {
      text-decoration: none;
      &.external {
        display: flex;
      }
    }
  }
}
</style>
