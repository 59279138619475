<template>
  <modal ref="modal" @close="closeModal()" width="431px">
    <div class="app-info-modal">
      <h3>
        {{name}}
        <icon-button @click="closeModal">
            <img src="../../assets/close--white.svg" alt="close icon">
        </icon-button>
      </h3>
      <div class="app-info">
        <div>
          <span>{{`${name} - daily compounded`}}</span>
          <span>{{`${rewards}%`}}</span>
        </div>
        <div>
          <span>{{`Total ${name}`}}</span>
          <span>{{`${yieldOnly}%`}}</span>
        </div>
      </div>
      <custom-table :headers="headers" :rows="rates"></custom-table>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import IconButton from '../IconButton';
import CustomTable from '../CustomTable';

export default {
  name: 'AppInfoModal',
  props: {
    name: {
      type: String,
      require: true
    },
    rates: {
      type: Array,
      require: true
    },
    dots: {
      type: Array
    },
    yieldOnly: {
      type: Number
    },
    rewards: {
      type: Number
    }
  },
  data() {
    return {
      headers: ['Timeframe', 'ROI', 'SEFI']
    }
  },
  components: { Modal, IconButton, CustomTable },
  methods: {
    openModal() {
      this.$refs.modal && this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal && this.$refs.modal.close();
    }
  }
}
</script>

<style lang="scss" scoped>

.app-info-modal {
  background-color: $primary-02;
  padding: 1.5rem;
  display: grid;
  grid-row-gap: 26px;
  h3 {
    position: relative;
    text-align: center;
    line-height: 31.2px;
    .icon-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .app-info {
    display: grid;
    grid-row-gap: 10px;
    > div {
      display: grid;
      grid-template-columns: 1fr auto;
      span {
        line-height: 1.5rem;
      }
    }
  }
  ul {
    line-height: 1.5rem;
    li {
      display: grid;
      grid-template-columns: auto 1fr;
      div {
        border-radius: 50%;
        width: 4px;
        height: 4px;
        margin: 10px;
        background-color: $neutral-06;
      }
      > * {
        color: $neutral-06;
      }
    }
  }
}

</style>
