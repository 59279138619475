<template>
  <div class="faq-list">
    <h1>FAQ</h1>

    <div>
      <h3>What is Duplex.Finance?</h3>
      <p>Duplex is an investment program set to deliver unprecedented yield on stablecoins, introducing a game-changing two layer strategy that outperforms any other product in the market. The innovation of Duplex’s strategy is the ability to utilize a single invested asset to generate yield from two distinct sources.</p>
    </div>

    <div>
      <h3>How does Duplex.Finance work?</h3>
      <p>Traditionally, investors can either: (1) invest their stablecoins in an Ethereum based investment product, or alternatively (2) cross a bridge to a layer 2 network and invest an equivalent, synthetic token in that network, effectively locking their “original / ERC-20” as an idle collateral in a bridge.</p>
      <br>
      <p>Duplex.Finance enables investors to do both (1) and (2)!</p>
      <br>
      <p>Duplex.Finance utilizes a purpose built Secret Network bridge, that instead of holding the ERC-20’s idle as collateral, is using keyTango’s Catalyst platform to deploy the ERC-20’s into best-in-class Ethereum based investment products.</p>
    </div>

    <div>
      <h3>How can I join Duplex.Finance?</h3>
      <p>Due to programs’ $5M volume cap, participation in Duplex.finance is limited to an ‘invite only’ basis, which requires interested users to apply through a waitlisting process.</p>
      <a href="" @click.prevent="updateSwitchRequestAccess">Request access to Duplex.Finance now</a>
    </div>

    <div>
      <h3>What are the basic requirements to join Duplex.Finance?</h3>
      <p>A minimum deposit value of $10k and an invite to participate.</p>
    </div>

    <div>
      <h3>How can I receive an invite?</h3>
      <p>The first way to receive an invite is by requesting access to participate and filling a short form. The participation plan highly prioritizes Secret Network or keyTango community members, allowing them to gain early access to the program and generate other substantial benefits.</p>
      <p>The second way to receive an invite is by referral, which can be accepted only from already registered Duplex.Finance members.</p>
    </div>

    <div>
      <h3>How can I receive referral rights?</h3>
      <p>Users qualify as higher tier community members by providing added value, and gain two perks: (1) unlock Duplex's tier 1, $100k cap (2) get a referral code for an additional invite.</p>
    </div>

    <div>
      <h3>How would I know if my request was granted?</h3>
      <p>Approved users will receive an informing email accompanied with one-time code that grants access to Duplex’s investment app.</p>
    </div>

    <div>
      <h3>Who are the developers of Duplex.Finance?</h3>
      <p>Duplex.Finance is powered by Secret Network & keyTango.</p>
      <br>
      <i>About Secret Network</i>
      <p>Secret Network is the first blockchain with data privacy by default, allowing you to build and use applications that are both permissionless and privacy-preserving. This unique functionality protects users, secures applications, and unlocks hundreds of never-before-possible use cases for Web3.</p>
      <a href="https://scrt.network" target="_blank" rel="noopener noreferrer">https://scrt.network</a>

      <br>
      <br>

      <i>About keyTango</i>
      <p>keyTango is a platform for retail investors to discover, learn and invest in deep DeFi products such as yield farming and liquidity pools. We believe that DeFi space should be simple & easy to navigate. Our team consists of MIT, Ycombinator and Enigma MPC alumni. We are proud to be backed by Outlier Ventures and other leading crypto investors.</p>
      <a href="https://keytango.io" target="_blank" rel="noopener noreferrer">https://keytango.io</a>

      <br>
      <br>

      <b class="highlight">Disclaimer</b>
      <p class="highlight">
        <i>
          * Please note that this is an unaudited pilot programme. Trading cryptocurrencies carries a high level of risk, and may not be suitable for all investors. Before deciding to trade cryptocurrencies you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose.
        </i>
      </p>
      <br>
      <p class="highlight">
        <i>
          Enigma and/or keyTango will not accept liability for any loss or damage, including without limitation to, any loss of profit, which may arise directly or indirectly.
        </i>
      </p>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FAQList',
  props: {
    faqs: {
      type: Array
    }
  },
  computed: {
    currentFaq() {
      return this.urldecode(this.$route.hash);
    }
  },
  methods: {
    urldecode(str) {
      return decodeURIComponent((str+'').replace(/\+/g, '%20')).replaceAll("#", "");
    },

    ...mapActions({ 
      updateSwitchRequestAccess: 'oneTimeStore/updateSwitchRequestAccess'
    })

  }
}
</script>

<style lang="scss" scoped>
.faq-list {
  width: 100%;
  max-width: 1168px;
  margin: 0 auto;
  background-color: rgba($primary-04, 0.85);
  position: relative;

  display: grid;
  grid-row-gap: 26px;

  @include respond-to("medium and down") {
    padding: 24px;
  }

  @include respond-to("large and up") {
    padding: 80px;
  }

  h1 {
    color: $secondary-03;

    @include respond-to("large and up") {
      position: absolute;
      left: 80px;
      top: -38.3625px;
      background-color: $primary-01;
      padding: 16px 32px;
    }
  }

  i {
    font-style: italic;
  }

  h3 {
    color: $secondary-03;
    margin-bottom: 4px;
  }
}
</style>
