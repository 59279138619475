<template>
  <div class="deposit">

    <loading v-if="isLoading" :text="loaderText" />

    <div v-else>
      <div class="text-two-columns">
        <h4>Available to Deposit</h4>
        <span>
          <balance-displayer contract-id="sust" currency="UST">Show Balance</balance-displayer>
        </span>
      </div>
      <validation-observer v-slot="{ validate, invalid }">
        <form @submit.prevent="validate().then(submit)" autocomplete="off">

          <validation-provider tag="fieldset" :rules="{required: true, max_value: balance && tier.max, min_value: tier.min}">
            <small v-if="!tier.id">...</small>
            <small v-else>
              <b>Tier {{ tier.id }}:</b>
              you can
              <span class="highlight--secondary">deposit {{ formatNumber(tier.min) }} as minimum</span>
              , and
              <span class="highlight--secondary">{{ formatNumber(tier.max) }} as maximum</span>.
            </small>
            <currency-input v-model="currentAmount" currency="UST" :maxValue="balance"/>
          </validation-provider>

          <button class="primary" :disabled="invalid">Deposit</button>
        </form>
      </validation-observer>

    </div>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import BalanceDisplayer from './BalanceDisplayer.vue';
import CurrencyInput from './CurrencyInput.vue'
import Loading from './Loading.vue'

import { coinConvert } from '@stakeordie/griptape.js';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BalanceDisplayer,
    CurrencyInput,
    Loading,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      isLoading: false,
      currentAmount: null,
      token: null,
      tier: {
        id: '',
        min: 0,
        max: 0
      }
    }
  },

  mounted() {
    this.getTier();
  },

  methods: {
    ...mapActions({
      deposit: 'sust/deposit'
    }),

    async submit() {
      if (!this.currentAmount) return;
      const amount = coinConvert(this.currentAmount, 18, 'machine');
      this.isLoading = true;
      await this.deposit(amount);
      this.currentAmount = null;
      this.isLoading = false;
    },

    async getTier() {
      this.tier = await this.$api.getTier();
    },

    formatNumber(number) {
      if (number < 1000000) {
        return `${number / 1000}K`
      } else {
        return `${number / 1000000}MM`
      }
    }
  },

  computed: {
    loaderText() {
      return `Depositing ${this.currentAmount} UST...`
    },

    ...mapState('sust', {
      sustBalance: state => state.balance
    }),

    balance() {
      return this.sustBalance ? Number(coinConvert(this.sustBalance, 18, 'human')) : 0;
    }
  }
}
</script>

<style lang="scss" scoped>

.deposit {
  color: $primary-02;

  div {
    display: grid;
    grid-row-gap: 27px;
    text-align: center;
    &:last-child {
      border-radius: 0 0 1rem 1rem;
    }
    .text-two-columns {
      display: grid;
      grid-template-columns: max-content 1fr;
      color: $primary-09;
      :first-child {
        justify-self: start;
      }
      :last-child {
        justify-self: end;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
