<template>
  <ul class="tabs">
    <li v-for="(tab, index) in tabs" :key="index" :class="{selected: tab === currentTab}" @click.prevent="changeTab(tab)">
      <h4>{{tab}}</h4>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array
    }
  },
  data() {
    return {
      currentTab: this.tabs && this.tabs[0]
    }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      let value = tab.replaceAll(" ", "").toUpperCase();
      this.$emit('onSelect', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: grid;
  grid-auto-flow: column;
  li {
    cursor: pointer;
    list-style: none;
    border-bottom: 4px solid $primary-04;
    h4 {
      margin-bottom: 10px;
      color: $primary-10;
      text-align: center;
    }
    &.selected {
      border-bottom: 4px solid $secondary-03;
      h4 {
        color: $secondary-03;
      }
    }
  }
}
</style>
