<template>
  <div class="messages">
    <transition-group tag="ul" enter-active-class="animated fadeInDown" leave-active-class="animated bounceOutRight">
      <li class="messages__msg" :class="msg.type" v-for="msg in messages" :key="msg.id">
        {{msg.message}}
        <a href="" @click.prevent="close(msg)">
          <img src="../assets/close.svg" alt="close icon">
        </a>
      </li>
    </transition-group>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'MessageCenter',
    computed: {
      ...mapState('messagesStore', {
        messages: state => state.messages
      }),
    },
    methods: {

      ...mapActions({ 
        hideMessage: 'messagesStore/hideMessage'
      }),

      close(message) {
        this.hideMessage(message);
      },
    },
  };
</script>

<style lang="scss" scoped>

  .messages {
    width: 100%;
    display: grid;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    a {
      display: grid;
      align-items: center;
    }
    &__msg {
      &.error {
        background-color: $red-01;
      }
      &.warning {
        background-color: $secondary-05;
      }
      &.success {
        background-color: $green-01;
      }
      color: $neutral-03;

      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      justify-items: center;
      font-size: 1rem;
      font-family: 1rem;
      line-height: 1.5rem;
      border-radius: 4px;
      padding: 16px;
      min-width: 500px;
      margin-top: 8px;
    }
  }
</style>
