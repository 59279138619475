import axios from 'axios'

import testnetTiers from './duplex.tiers.testnet.json';
import mainnetTiers from './duplex.tiers.mainnet.json';

const apiUrl = process.env.VUE_APP_API_URL;
const serviceUrl = process.env.VUE_APP_API_SERVICE_URL;
const googleFormApi = process.env.VUE_APP_GOOGLE_FORM_API;
const STORAGE_EMAIL_KEY = 'EMAIL';

export const TIER_1 = 'Tier 1: $10K - $100K';
export const TIER_2 = 'Tier 2: $100K - $250K';
export const TIER_3 = 'Tier 3: $250K - $1MM';

const showTestnetTiers = process.env.VUE_APP_TESTNET_TIERS || false;

function getTiers() {
  return showTestnetTiers ? testnetTiers : mainnetTiers;
}

export default {
  install(Vue) {

    Vue.prototype.$api = {

      addCode(email) {
        localStorage.setItem(STORAGE_EMAIL_KEY, email);
        return axios.post(`${apiUrl}/addCode`, { email: email });
      },

      markCodeAsUsed(code) {
        return axios.put(`${apiUrl}/markAsUsed`, { code });
      },

      getApr() {
        return axios.get(`${serviceUrl}/apr`);
      },

      getTokenLimit() {
        return axios.get(`${serviceUrl}/tokenlimit`);
      },

      async getTier() {
        const tiers = getTiers();
        const email = localStorage.getItem(STORAGE_EMAIL_KEY);
        if (!email) {
          return tiers[TIER_1];
        }
        try {
          const res = await axios.get(`${apiUrl}/getTier?email=${email}`)
          const { tier } = res.data;
          return tiers[tier];
        } catch (e) {
          return tiers[TIER_1];
        }
      },

      submitForm(form) {

        const {
          emailAddress,
          referredBy,
          tier,
          fields,
          otherField,
          hasTokens,
          tokens,
          contribution
        } = form;

        return axios.post(
          googleFormApi,
          {
            "urlId":"1FAIpQLSeL_R0m_i4gtKwOVaGoHRUnl2LAOEyyh0GIFAxU3j3ZwPQxPw",
            "emailAddress": emailAddress,
            "entry.1474571294": referredBy,
            "entry.2120412583": tier,
            "entry.1506974642": fields,
            "entry.1506974642.other_option_response": otherField,
            "entry.353922903": hasTokens,
            "entry.1866249431": tokens,
            "entry.32949025": contribution
          }
        )
      }
    }
  }
}
