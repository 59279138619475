const DONT_SHOW_KEPLR_MODAL_AGAIN = "DONT_SHOW_KEPLR_MODAL_AGAIN";

export default {
  namespaced: true,

  state: {
    dontShowModalAgain: false,
    switchKeplrModal: false
  },

  getters: {
    dontShowModalAgain: (state) => {
      return localStorage.getItem(DONT_SHOW_KEPLR_MODAL_AGAIN) || state.dontShowModalAgain;
    }
  },

  mutations: {
    showModalAgain(state) {
      state.dontShowModalAgain = false;
    },
    dontShowModalAgain(state) {
      state.dontShowModalAgain = true;
    },
    updateSwitchKeplrModal(state) {
      state.switchKeplrModal = !state.switchKeplrModal
    }
  },

  actions: {
    dontShowModalAgain({commit}) {
      localStorage.setItem(DONT_SHOW_KEPLR_MODAL_AGAIN, true);
      commit('dontShowModalAgain');
    },
    updateSwitchKeplrModal({commit}) {
      commit('updateSwitchKeplrModal');
    },
    forceUpdateSwitchKeplrModal({commit}) {
      localStorage.removeItem(DONT_SHOW_KEPLR_MODAL_AGAIN);
      commit('showModalAgain');
      commit('updateSwitchKeplrModal');
    }
  }
};
