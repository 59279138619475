<template>
  <div>
    <div class="app-card custom-scroll" :class="{small: small}">
      <div class="section">

        <liquidity-pool :fromCurrency="fromCurrency"></liquidity-pool>

        <div class="donut-content">
          <loading v-if="isLoadign"></loading>

          <donut-chart v-else :percentages="percentages" donut-title="Current APR" :small="small"></donut-chart>
        </div>

        <notes is-card>
          <template #headline>What does this do?</template>
          <template #body>Depositing into this pool will stake your UST in Secret Network, in a pool which earns yield from multiple sources – both on Secret Network and using an optimized yield strategy on Ethereum.</template>
        </notes>

        <meter-progress :value="1.4" :max-value="2" :currency="fromCurrency"></meter-progress>

      </div>

      <div class="section">

        <tabs :tabs="tabs" @onSelect="changeTab"></tabs>

        <deposit v-show="currentTab === 'DEPOSIT'"></deposit>

        <withdraw v-show="currentTab === 'WITHDRAW'"></withdraw>

        <claim v-show="currentTab === 'CLAIM'" :claim="15.8479" :currency="fromCurrency"></claim>

      </div>

      <app-info-modal name="APY" :rewards="56.47" :yield-only="53.84" :rates="rates" :dots="dots" ref="appInfoModal"></app-info-modal>
    </div>

    <balances></balances>
  </div>
</template>

<script>
import Tabs from './Tabs';
import MeterProgress from './MeterProgress';
import Notes from './Notes';
import DonutChart from './DonutChart';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import Claim from './Claim';
import LiquidityPool from './LiquidityPool';
import AppInfoModal from './modal/AppInfoModal';
import Loading from './Loading';
import Balances from './Balances';
import { mapActions } from 'vuex';

export default {
  name: 'NavigationBar',
  props: {
    small: {
      type: Boolean
    }
  },
  components: {
    Tabs,
    MeterProgress,
    Notes,
    DonutChart,
    Deposit,
    Withdraw,
    Claim,
    LiquidityPool,
    AppInfoModal,
    Loading,
    Balances
  },
  data() {
    return {
      tabs: ['Deposit', 'Withdraw', 'Claim'],
      fromCurrency: 'UST',
      percentages: [],
      initPercentages: {
        market: {
          number: 0,
          text: "Market APR",
          color: "#538FDF"
        },
        secretswap_rewards: {
          number: 0,
          text: "SecretNetwork APR",
          color: "#E1C442"
        }
      },
      currentTab: "DEPOSIT",
      rates: [
        { timeFrame: '1d', roi: 0.15, conversion: 0.08 },
        { timeFrame: '7d', roi: 1.03, conversion: 0.57 },
        { timeFrame: '30d', roi: 4.51, conversion: 2.49 },
        { timeFrame: '365d', roi: 71.25, conversion: 39.31 }
      ],
      dots: [
        "Calculated based on current rates.",
        "Compunding 1x daily.",
        "LP rwards: 0.17% trading fees, distributed proportionally among LP token holders.",
        "All igures are estimates provided for your convenience only, and by no means represent guaranteed returns."
      ],
      isLoadign: false
    }
  },
  mounted() {
    this.getApr();
  },
  methods: {
    ...mapActions({ 
      message: 'messagesStore/message'
    }),

    async getApr() {
      this.isLoadign = true;
      try {
        const { data } = await this.$api.getApr();
        const keys = Object.keys(data);
        this.percentages = [];
        keys.forEach(key => {
          if (this.initPercentages[key]) {
            this.initPercentages[key].number = data[key];
            this.percentages.push(this.initPercentages[key]);
          }
        });        
      } catch (error) {
        this.message({ type: 'error', message: 'An error has ocurred.' });
      }
      this.isLoadign = false;
    },

    changeTab(tab) {
      this.currentTab = tab;
    },

    deposit() {
      this.message({type: 'success', message: 'Your deposit was successful!'});
    }
  }
}
</script>

<style lang="scss" scoped>
.app-card {
  width: 100%;
  max-width: 568px;
  border-radius: 1rem;
  color: $primary-02;
  background-color: $primary-02;
  h3 {
    justify-self: center;
    white-space: nowrap;
    width: min-content;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 10px;
  }
  .donut-content {
    display: grid;
    align-items: center;
    @include respond-to("large and up") {
      min-height: 187px;
    }
  }
  .section {
    display: grid;
    grid-row-gap: 27px;
    text-align: center;
    padding: 24px;
    &:last-child {
      background-color: $primary-03;
      border-radius: 0 0 1rem 1rem;
    }
  }
  &.small {
    max-width: 268px;
    max-height: 352px;
    overflow: hidden;
    .section {
      padding: 1rem 0;
    }
    * {
      pointer-events: none;
    }
  }
}
</style>
