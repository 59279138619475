<template>
  <div class="generate-code-form">

    <validation-observer v-slot="{ validate, invalid }">

      <form class="generate-code-form__form" @submit.prevent="validate().then(submit)" autocomplete="off">

          <div class="heading">
            <h3>One-time Code</h3>
            <p>Type your email to generate a code. Use the same email that you used when you
            requested access.</p>
          </div>

          <validation-provider tag="fieldset" :rules="{required: true, email: true}">
            <label for="generate-code-email">Email</label>
            <input type="text" id="generate-code-email" placeholder="Email" v-model="email">
          </validation-provider>

          <loading-button class="primary" :disabled="invalid" :loading="isLoading">Submit</loading-button>

      </form>

    </validation-observer>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LoadingButton from '../LoadingButton';
import { mapActions } from 'vuex';

export default {
  name: 'GenerateCodeForm',
  props: {},
  data() {
    return {
      email: null,
      isLoading: false
    }
  },
  components: { ValidationObserver, ValidationProvider, LoadingButton },
  methods: {

    ...mapActions({ 
      message: 'messagesStore/message',
      hasSentAnEmail: 'oneTimeStore/hasSentAnEmail'
    }),

    ...mapActions('oneTimeStore', [
      'hasSentAnEmail'
    ]),

    async submit() {
      this.isLoading = true;
      try {
        await this.$api.addCode(this.email);
        this.message({type: 'success', message: `Your code has been sent to your email.`});
        this.hasSentAnEmail();
      } catch (e) {
        const error = e.response.data.error;
        if ("email_not_found" === error) {
          this.message({type: 'warning', message: `The email is not registered`});
        } else if ("code_already_used" === error) {
          this.message({type: 'warning', message: `There is already a code for that email`});
        }

      }
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.generate-code-form {
  background-color: $primary-02;
  .heading {
    text-align: center;
  }
  &__form {
    display: grid;
    grid-row-gap: 26px;

  }

}

</style>
