<template>
  <div class="coming-soon" :class="{small: small}">Coming Soon</div>
</template>

<script>

export default {
  name: 'ComingSoon',
  components: {},
  props: {
    small: {
      type: Boolean
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.coming-soon {
  display: grid;
  width: 100vw;
  max-width: 568px;

  border-radius: 1rem;
  background-color: $primary-02;
  text-align: center;
  align-items: center;

  span {
    height: max-content;
  }
  &:not(.small) {
    height: 100vh;
    max-height: 568px;
  }
  &.small {
    width: 268px;
    height: 352px;
  }
}
</style>
