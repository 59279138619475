<template>
  <div class="meter-progress">
    <div class="deposit">
      <span>Deposited</span>
      <h4 v-if="!value">...</h4>
      <h4 v-else>{{`${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currency}`}}</h4>
    </div>
    <div class="max-capacity">
      <span>Max Capacity</span>
      <h4>{{`${maxCapacity ? `${maxCapacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currency}` : '...'}`}}</h4>
    </div>
    <div class="progress">
      <div class="current-progress" :style="{width: currentPercentage}"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { coinConvert } from '@stakeordie/griptape.js';

const DEFAULT_TOTAL_LOCKED = 1372465;

export default {
  props: {
    currency: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      currentTab: this.tabs && this.tabs[0],
      maxCapacity: 0
    }
  },

  created() {
    this.updateTotalLocked();
  },

  mounted() {
    this.getTokenLimit();
  },

  methods: {
    ...mapActions('ustStakingPool', [
      'updateTotalLocked'
    ]),

    isNumber(value) {
      return !isNaN(Number(value));
    },

    async getTokenLimit() {
      try {
        const { data } = await this.$api.getTokenLimit();
        this.maxCapacity = data.UST && data.UST.limit;

      } catch (error) {
        this.message({ type: 'error', message: 'An error has ocurred.' });
      }
    }

  },

  computed: {
    ...mapState('ustStakingPool', {
      totalLocked: state => state.totalLocked
    }),

    value() {
      const rawTotalLocked = coinConvert(this.totalLocked, 18, 'human', 0);
      if (!rawTotalLocked) return '';
      const totalLocked = parseInt(rawTotalLocked);
      if (totalLocked > DEFAULT_TOTAL_LOCKED) {
        return totalLocked;
      }
      return DEFAULT_TOTAL_LOCKED;
    },

    currentPercentage() {
      const value = Number(this.value);
      const maxCapacity = Number(this.maxCapacity);
      if (this.isNumber(value) && this.isNumber(maxCapacity) && maxCapacity > 0) {
        let percentage = value * 100 / maxCapacity;
        return percentage > 100 ? '100%' : `${percentage}%`;
      }
      return 0;
    }
  }
}
</script>

<style lang="scss" scoped>

.meter-progress {
  width: 100%;
  max-width: 680px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "deposit max-capacity" "progress progress";
  grid-row-gap: 16px;
  .deposit, .max-capacity {
    display: grid;
    grid-row-gap: 4px;
    span {
      color: $primary-09;
    }
  }
  .deposit {
    text-align: start;
    h4 {
      color: $neutral-white;
    }
  }
  .max-capacity {
    text-align: end;
  }
  .progress, .current-progress {
    grid-area: progress;
    height: 8px;
    border-radius: 4px;
    background-color: $primary-03;
    transition: width .5s;
    .current-progress {
      background-color: $neutral-white;
    }
  }
  &.yellow {
    .deposit > h4 { color: $secondary-03; }
    .current-progress { background-color: $secondary-03 }
  }
}
</style>
