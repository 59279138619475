<template>
  <button class="loading-button" :class="{'loading': loading}">
    <loading class="small" v-if="loading"></loading>
    <slot v-else></slot>
  </button>
</template>

<script>
import Loading from "./Loading.vue";

export default {
  name: 'LoadingButton',
  components: { Loading },
  props: {
    loading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>

.loading-button {
  display: grid;
  align-items: center;
  justify-items: center;
  &.loading {
    height: 56px;
    padding: 0;
  }
}
</style>
