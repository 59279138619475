import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import FAQPage from '../pages/FAQPage.vue'
import ApplicationsPage from '../pages/ApplicationsPage.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: HomePage
  },
  // By request bridge is applications 
  {
    path: '/apps',
    name: 'applications',
    component: ApplicationsPage,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/faq',
    name: 'faq-page',
    component: FAQPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      return {x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {

  const store = require('../stores').default;

  if (to.meta.authRequired && !store.getters['oneTimeStore/oneTimeCode']) {
    router.replace({ name: 'homepage' }).catch(() => {});
    return
  }

  if (to.name === 'applications') {
    store.dispatch('keplrStore/updateSwitchKeplrModal');
  }

  next();
});

export default router
