import { createContract } from '@stakeordie/griptape.js';

const {
  // UST Staking Pool contract address.
  VUE_APP_UST_STAKING_POOL_ADDRESS: ustStakingPoolContractAddress
} = process.env;

const def = {
  messages: {
    redeem(_, amount) {
      const fees = 175000;
      const handleMsg = {
        redeem: {
          amount
        }
      };
      return { handleMsg, fees };
    }
  },

  queries: {
    getBalance({ address, key }) {
      return { balance: { address, key } };
    },

    getRewards({ address, key, height }) {
      return { rewards: { address, key, height } };
    },

    getTotalLocked() {
      return { total_locked: { } };
    }
  }
};

export const ustStakingPool = createContract({
  id: 'ust-staking-pool',
  at: ustStakingPoolContractAddress,
  definition: def
});
