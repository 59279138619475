import Vue from 'vue';
import Vuex from 'vuex';

import sust from './sust-store';
import ustStakingPool from './ust-staking-pool-store';
import messagesStore from './messages-store';
import oneTimeStore from './one-time-store';
import keplrStore from './keplr-store'

import { onAccountAvailable } from '@stakeordie/griptape.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    sust,
    ustStakingPool,
    messagesStore,
    oneTimeStore,
    keplrStore
  }
});

onAccountAvailable(() => {
  store.dispatch('ustStakingPool/updateRewards');
});

export default store;
