import { sust } from '@/contracts';
import { getError } from './errors';
import { keplrViewingKeyManager } from '@stakeordie/griptape.js';

export default {
  namespaced: true,

  state: {
    // The balance for SUST.
    balance: undefined
  },

  // Hate Vuex.
  mutations: {
    updateBalance: (state, balance) => state.balance = balance,
  },

  actions: {
    // Updates the UST balance.
    async updateBalance({ commit }) {
      const vk = keplrViewingKeyManager.get(sust.at);
      if (!vk) return;
      const res = await sust.getBalance();
      commit('updateBalance', res.balance.amount);
    },

    // Perform a deposit to the UST Staking Pool.
    async deposit({ dispatch }, amount) {
      try {
        await sust.deposit(amount);
        dispatch('messagesStore/message', { type: 'success', message: 'You have successfully deposited!' }, { root: true });
      } catch (e) {
        const { type, message, key } = getError(e);
        if (key === 'keplr-rejected') return;
        dispatch('messagesStore/message', { type, message }, { root: true });
      } finally {
        dispatch('updateBalance')

        dispatch('ustStakingPool/updateBalance', null, { root: true });
        dispatch('ustStakingPool/updateRewards', null, { root: true });
        dispatch('ustStakingPool/updateTotalLocked', null, { root: true });
      }
    }
  }
};
