import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './stores';
import './sass/index.scss';
import {
  gripApp,
  getKeplrAccountProvider,
  onAccountChange,
  BroadcastMode
} from '@stakeordie/griptape.js';
import Api from '@/stores/duplex'
import Vue2TouchEvents from 'vue2-touch-events'

import BaseLayout from './layout/BaseLayout.vue';
import ColumnBlock from './components/ColumnBlock.vue';

import { extend } from 'vee-validate';
import { required, email, max_value, min_value, is } from 'vee-validate/dist/rules';

Vue.use(Api);
Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false;

extend('required', { ...required });
extend('email', { ...email });
extend('max_value', { ...max_value });
extend('min_value', { ...min_value });
extend('is', { ...is });

Vue.component('BaseLayout', BaseLayout);
Vue.component('ColumnBlock', ColumnBlock);

const restUrl = process.env.VUE_APP_SECRET_NODE_REST_URL;
const provider = getKeplrAccountProvider();

const config = {
  restUrl,
  broadcastMode: BroadcastMode.Block
};

onAccountChange(() => {
  window.location.reload();
});

const runApp = () => {
  new Vue({
    router,
    store,
    render: function (h) { return h(App) },
  }).$mount('#app');
};

gripApp(config, provider, runApp);
