<template>
  <a href="" class="icon-button" @click.prevent="$emit('click')">
    <slot></slot>
  </a>
</template>

<script>

export default {
  name: 'IconButton',
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.icon-button {
  display: grid;
  align-items: center;
}
</style>
