<template>
  <modal ref="modal" @close="closeModal()" width="800px">
    <request-access-form @closeModal="closeModal"></request-access-form>
  </modal>
</template>

<script>
import Modal from './Modal';
import RequestAccessForm from '../forms/RequestAccessForm';

export default {
  name: 'RequestAccessModal',
  props: {},
  components: { Modal, RequestAccessForm },
  methods: {
    openModal() {
      this.$refs.modal && this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal && this.$refs.modal.close();
    }
  }
}
</script>

<style lang="scss" scoped></style>
