<template>
  <div class="balance-pill">
    <span class="balance-pill__label">Duplex Balance</span>
    <span class="balance-pill__amount" v-if="ustStakingPoolBalance"><b>{{ ustStakingPoolBalance }} UST</b></span>
    <span class="balance-pill__amount" v-else>...</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { coinConvert } from '@stakeordie/griptape.js';
import numeral from 'numeral';

export default {
  methods: {
    formatBalance(balance, decimals) {
      if (!balance) return;
      const result = coinConvert(balance, decimals, 'human', 2);
      return numeral(result).format('(0.0a)');
    }
  },

  computed: {
    ...mapState('ustStakingPool', {
      rawUstStakingPoolBalance: state => state.balance
    }),

    ustStakingPoolBalance() {
      return this.formatBalance(this.rawUstStakingPoolBalance, 18);
    },
  }
}
</script>

<style lang="scss" scoped>
.balance-pill {
  padding: 4px 16px;
  border-radius: 17px;
  background-color: white;
  overflow: hidden;

  span {
    &:first-child {
      margin-right: 10px;
    }
    color: $primary-01 !important;
  }

  &__label {
    @include respond-to("large and down") {
      display: none;
    }
  }

  &__amount {
    text-transform: uppercase;
  }
}
</style>
