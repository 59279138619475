<template>
  <base-layout>
    <div class="applications-page">
      <h3>Applications</h3>
      <div class="applications-page__content">
        <aplications-list></aplications-list>
      </div>
    </div>
  </base-layout>
</template>

<script>
import AplicationsList from '@/components/AplicationsList.vue';
import { bootstrap } from '@stakeordie/griptape.js';

export default {
  components: { AplicationsList },

  mounted() {
    bootstrap();
  }
}
</script>
<style lang="scss" scoped>
.applications-page {
  display: grid;
  grid-row-gap: 1rem;
  grid-auto-rows: min-content;
  justify-items: center;
  h3 {
    @include respond-to("large and up") {
      margin: 2rem 0;
    }
    color: $primary-09;
  }
  &__content {
    display: grid;
    justify-content: center;
  }
}
</style>
