<template>
  <div class="request-access-form">

    <validation-observer v-slot="{ validate, invalid }">

      <form class="request-access-form__form custom-scroll" @submit.prevent="validate().then(submit)" autocomplete="off">

          <h3>Duplex.Finance "First & Finest" Check-In
            <icon-button @click="closeModal">
                <img src="../../assets/close--white.svg" alt="close icon">
            </icon-button>
          </h3>

          <validation-provider tag="fieldset" :rules="{required: true}">
            <label for="request-access-email">Insert Email address for contacting<span class="require">*</span></label>
            <input id="request-access-email" type="text" placeholder="Your answer" maxlength="255" v-model="form.emailAddress">
          </validation-provider>

          <validation-provider tag="fieldset">
            <label for="requested-access-referred-by">Referred by</label>
            <input id="requested-access-referred-by" type="text" placeholder="Your answer" maxlength="255" v-model="form.referredBy">
          </validation-provider>

          <validation-provider tag="fieldset" class="radio" :rules="{required: true}">
            <label for="">I want to participate as (choose one)<span class="require">*</span></label>

            <div class="inputs">
              <radio-input radio-id="tier-1" name="tier" radio-value="Tier 1: $10K - $100K" v-model="form.tier"></radio-input>
              <radio-input radio-id="tier-2" name="tier" radio-value="Tier 2: $100K - $250K" v-model="form.tier"></radio-input>
              <radio-input radio-id="tier-3" name="tier" radio-value="Tier 3: $250K - $1MM" v-model="form.tier"></radio-input>
            </div>

          </validation-provider>

          <validation-provider tag="fieldset" class="checkbox" :rules="{required: true}">
            <label>What is your field / business (you can choose multiple options)?<span class="require">*</span></label>
            <div class="inputs">
              <label v-for="(field, index) in fields" :key="index" class="checkbox">
                <input type="checkbox" :name="field" v-model="form.fields" :value="field">
                <span></span>
                <div>
                  {{`${field}${field === '__other_option__' && haveOtherField ? ':' : ''}` | decoratorLabel}}
                  <input v-if="field === '__other_option__' && haveOtherField" class="other-value" type="text" v-model="form.otherField" required>
                </div>
              </label>

            </div>

          </validation-provider>

          <validation-provider tag="fieldset" class="radio" :rules="{required: true}">
            <label for="">Do you hold a $TANGO/ $SCRT/ $SEFI token?<span class="require">*</span></label>

            <div class="inputs">
              <radio-input radio-id="yes-has-tokens" name="has-tokens" radio-value="Yes" v-model="form.hasTokens"></radio-input>
              <radio-input radio-id="no-has-tokens" name="has-tokens" radio-value="No" v-model="form.hasTokens"></radio-input>
            </div>

          </validation-provider>

          <validation-provider tag="fieldset">
            <label for="request-access-tokens">If so, what amount do you have?</label>
            <input id="request-access-tokens" type="number" placeholder="Amount" v-model="form.tokens">
          </validation-provider>

          <validation-provider tag="fieldset">
            <label for="request-access-contribution">How can you contribute to this project? Meaningful offers might increase approval chances.</label>
            <input id="request-access-contribution" type="text" placeholder="Your answer" maxlength="255" v-model="form.contribution">
          </validation-provider>

          <div class="actions">
            <button class="tertiary" @submit.prevent="" @click="closeModal">Cancel</button>
            <button class="primary" :disabled="invalid">Submit</button>
          </div>

      </form>

    </validation-observer>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import IconButton from '../IconButton';
import RadioInput from './RadioInput';
import { mapActions } from 'vuex';

export default {
  name: 'RequestAccessModal',
  props: {},
  filters: {
    decoratorLabel(value) {
      return value.replace("__other_option__", "Other");
    }
  },
  data() {
    return {
      form: {
        emailAddress: null,
        referredBy: null,
        tier: null,
        fields: [],
        otherField: null,
        hasTokens: null,
        tokens: null,
        contribution: null
      },
      fields: ["Investor", "Venture Capital", "Media", "Influencer", "__other_option__"]
    }
  },
  components: { ValidationObserver, ValidationProvider, IconButton, RadioInput },
  computed: {
    haveOtherField() {
      return this.form.fields && this.form.fields.indexOf("__other_option__") > -1;
    }
  },
  methods: {

    ...mapActions({ 
      message: 'messagesStore/message'
    }),

    closeModal() {
      this.$emit('closeModal');
    },

    async submit() {
      try {
        await this.$api.submitForm(this.form);
        this.message({type: 'success', message: 'Your request has been submitted' });
      } catch (e) {
        this.message({type: 'error', message: 'Error has ocurred' });
      }
      this.closeModal();
    }
  }
}
</script>

<style lang="scss" scoped>

.request-access-form {
  @include respond-to("large and up") {
    form {
      padding-right: 10px;
      overflow-y: scroll;
      height: 86vh;
    }
  }

  background-color: $primary-02;

  padding: 1.5rem;

  h3 {
    display: grid;
    position: relative;
    text-align: center;
    .icon-button {
      position: absolute;
      top: 0;
      right: 0;

      @include respond-to("medium and down") {
        top: -16px;
        right: -16px;
      }
    }
  }
  .actions {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas: "submit" "cancel";
    @include respond-to("large and up") {
      justify-self: end;
      width: min-content;
      grid-template-areas: "cancel submit";
      button {
        width: 240px;
      }
    }
    button {

      display: grid;
      &.tertiary {
        grid-area: cancel;
      }

      &.primary {
        display: grid;
        grid-area: submit;
      }
    }
  }
}

</style>
