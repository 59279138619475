<template>
  <div class="currency-input" :class="{'large': maxValue}">
    <input type="number" v-model="currencyValue" @keyup="updateValue" placeholder="0.0">
    <a href="" v-if="maxValue" @click.prevent="setMaxValue">Max</a>
    <img :src="require(`../assets/${currency.toLowerCase()}.svg`)" alt="currency icon">
    <span>{{currency}}</span>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    value: {},
    maxValue: {
      type: Number
    },
    currency: {
      type: String
    }
  },
  data() {
    return {
      currencyValue: this.value
    }
  },
  methods: {
    setMaxValue() {
      this.currencyValue = this.maxValue;
      this.$emit('input', this.currencyValue);
    },
    updateValue() {
      if (this.maxValue && (this.value || this.currencyValue) > this.maxValue) {
        this.currencyValue = this.maxValue;
      }
      this.$emit('input', this.currencyValue);
    }
  }
}
</script>

<style lang="scss" scoped>

.currency-input {
  width: 100%;
  position: relative;
  display: grid;
  align-items: center;
  grid-column-gap: 8px;
  background-color: $primary-04;
  border-radius: 4px;
  grid-template-columns: 1fr max-content max-content;
  &.large {
    grid-template-columns: 1fr max-content max-content max-content;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
  input, span {
    background-color: $primary-04;
    color: $neutral-white;
    align-self: center;
    padding: 1rem;
    border-radius: 4px;
    line-height: 1.5rem;
    font-size: 1rem;
  }
  span {
    padding-left: 0;
  }

  a {
    padding: 4px 13px;
    // background-color: $secondary-03;
    // color: $primary-01 !important;
    // font-weight: 600;
    text-decoration: none;
    border-radius: 2rem;
    border: 1px solid $neutral-white;
    color: $neutral-white !important;
  }

  //Remove arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

}
</style>
