const errors = {
  'server-unavailable': {
    type: 'warning',
    message: 'There was a timeout when executing the transaction, but it might concluded successfully.'
  },
  'unexpected-error': {
    type: 'error',
    message: 'An error has occurred.'
  }
};

export function getError(e) {
  let key = 'unexpected-error';

  const err = e.toString();
  if (err.match(/502/ig)) {
    key = 'server-unavailable';
  } else if (err.match(/Request rejected/ig)) {
    key = 'keplr-rejected';
  }

  return { key, ...errors[key] };
}
