<template>
  <div class="header-bar">
    <router-link to="/" class="logo">Duplex.Finance</router-link>
    <div class="header-bar__nav">
      <navigation-bar :routes="routes"></navigation-bar>
    </div>
    <div class="header-bar__wallet">
      <wallet :onClick="openLaunchAppModal"></wallet>
      <balance-pill v-if="isAplicationPage"></balance-pill>
    </div>
    <generate-code-modal ref="generateCodeModal"></generate-code-modal>
    <request-access-modal ref="requestAccessModal"></request-access-modal>
  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar.vue'
import Wallet from '../components/Wallet.vue'
import GenerateCodeModal from '../components/modal/GenerateCodeModal';
import RequestAccessModal from '../components/modal/RequestAccessModal';
import BalancePill from '../components/BalancePill';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HeaderBar',
  components: {
    NavigationBar,
    Wallet,
    GenerateCodeModal,
    RequestAccessModal,
    BalancePill
  },
  data() {
    return {
      routes: [
        {title: "Home", name: 'homepage'},
        {title: "FAQ", name: 'faq-page'},
        {title: "Bridge", name: 'https://ust.scrt.network', external: true}
      ]
    }
  },
  watch: {
    'requestAccessSwitch'() {
      this.openRequestAccessModal();
    },
    'switchLaunchApp'() {
      this.openLaunchAppModal();
    }
  },
  computed: {
    ...mapState('oneTimeStore', {
      requestAccessSwitch: state => state.switchRequestAccess,
      switchLaunchApp: state => state.switchLaunchApp
    }),

    ...mapGetters({ 
      oneTimeCode: 'oneTimeStore/oneTimeCode'
    }),

    isAplicationPage() {
      return this.$route.name === 'applications';
    }
  },
  methods: {
    openRequestAccessModal () {
      this.$refs.requestAccessModal.openModal();
    },

    openLaunchAppModal() {

      if (this.oneTimeCode) {
        this.$router.push({name: "applications"});
        return;
      }

      this.$refs.generateCodeModal.openModal();
    }
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  background-color: $primary-01;
  padding: 0 24px;
  height: 80px;

  a {
    text-decoration: none;
  }

  @include respond-to("small and down") {
    padding-top: 28px;
    height: 100%;
  }

  &:first-child {
    display: grid;
    align-items: center;
  }
  &__wallet {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    & > :first-child {
      @include respond-to("medium and up") {
        margin-right: 16px;
      }
    }

    @include respond-to("small and down") {
      display: grid;
      justify-items: center;
      grid-column-gap: 10px;
      grid-auto-flow: column;
    }
  }
  @include respond-to("small and down") {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
</style>
