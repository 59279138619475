<template>
  <a href="" class="wallet" @click.prevent="isAplicationPage ? connect() : click()" :title="address">
    <div v-if="isAplicationPage">
      <img src="@/assets/wallet.svg" alt="wallet icon">
      <div class="wallet__address">
        <span v-if="!address">Connect Wallet</span>
        <span v-else>{{ address }}</span>
      </div>
    </div>
    <h4 v-else>Launch App</h4>
  </a>
</template>

<script>
import {
  bech32,
  bootstrap,
  getAddress,
  onAccountAvailable
} from '@stakeordie/griptape.js';

export default {
  props: {
    onClick: {
      type: Function
    }
  },

  data() {
    return {
      address: undefined
    }
  },

  computed: {
    isAplicationPage() {
      return this.$route.name === 'applications';
    }
  },

  mounted() {
    onAccountAvailable(() => {
      this.address = bech32(getAddress());
    });
  },

  methods: {
    async connect() {
      if (!this.address) {
        try {
          await bootstrap();
        } catch (e) {
          this.$store.dispatch('keplrStore/forceUpdateSwitchKeplrModal');
        }
      }
    },

    click() {
      this.onClick && this.onClick();
    }
  }
}
</script>

<style lang="scss" scoped>

.wallet {
  height: 2rem;
  display: grid;
  align-items: center;
  background-color: $primary-02;
  border: 1px solid $neutral-white;
  padding: 0 15px;
  border-radius: 17px;

  &__address {
    @include respond-to("medium") {
      display: none !important;
    }
  }

  div {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 10px;

    > div {
      display: grid;
      position: relative;
      justify-self: start;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      font-weight: 600;
      span {
        background-color: $primary-02;
        overflow: hidden;
        &:nth-child(2) {
          padding: 0 2px;
        }
        &:last-child {
          direction: rtl;
        }
      }
    }
  }
}
</style>
