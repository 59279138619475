import { ustStakingPool } from '@/contracts';
import { getError } from './errors';
import { keplrViewingKeyManager } from '@stakeordie/griptape.js';

export default {
  namespaced: true,

  state: {
    // The balance for SUST Staking Pool.
    balance: undefined,

    // Total value locked in the pool.
    totalLocked: undefined,

    // Total rewards.
    rewards: undefined
  },

  // Hate Vuex again.
  mutations: {
    updateBalance: (state, value) => state.balance = value,
    updateTotalLocked: (state, value) => state.totalLocked = value,
    updateRewards: (state, rewards) => state.rewards = rewards
  },

  actions: {
    // Update rewards.
    async updateRewards({ commit }) {
      const vk = keplrViewingKeyManager.get(ustStakingPool.at);
      if (!vk) return;
      const res = await ustStakingPool.getRewards();
      commit('updateRewards', res.rewards.rewards);
    },

    // Redeem rewards.
    async redeem({ dispatch }) {
      try {
        // redeem 0 is claiming rewards
        await ustStakingPool.redeem('0');

        dispatch('messagesStore/message', {
          type: 'success',
          message: 'You have sucessfully claimed rewards.' },
          { root: true }
        );
      } catch (e) {
        const { type, message, key } = getError(e);
        if (key === 'keplr-rejected') return;
        dispatch('messagesStore/message', { type, message }, { root: true });
      } finally {
        dispatch('updateRewards');
      }
    },

    // Update the UST Staking Pool balance.
    async updateBalance({ commit }) {
      const vk = keplrViewingKeyManager.get(ustStakingPool.at);
      if (!vk) return;
      const { balance: { amount } } = await ustStakingPool.getBalance();
      commit('updateBalance', amount);
    },

    // Update the total value locked.
    async updateTotalLocked({ commit }) {
      const { total_locked: { amount } } =
        await ustStakingPool.getTotalLocked();
      commit('updateTotalLocked', amount);
    },

    // Reedem from the UST Staking Pool contract.
    async withdraw({ dispatch }, amount) {
      try {
        // Reedem amount.
        await ustStakingPool.redeem(amount);

        dispatch('messagesStore/message', {
          type: 'success',
          message: 'You have successfully withdrawn!' },
          { root: true }
        );
      } catch (e) {
        const { type, message, key } = getError(e);
        if (key === 'keplr-rejected') return;
        dispatch('messagesStore/message', { type, message }, { root: true });
      } finally {
        dispatch('updateBalance');
        dispatch('updateRewards');
        dispatch('updateTotalLocked');

        dispatch('sust/updateBalance', null, { root: true });
      }
    }
  }
};
