<template>
  <div class="verification-code-form">

    <validation-observer v-slot="{ validate, invalid }">

      <form class="verification-code-form__form" @submit.prevent="validate().then(submit)">

          <div>
            <h3>One-time Code</h3>
            <p>You received a 6-digit code, please enter it below to access the application.</p>
            <p><a href="#" @click.prevent="hasNotSentAnEmail">Go back</a></p>
          </div>

          <validation-provider tag="fieldset" :rules="{required: true}">
            <code-input v-model="code" :digits="6"></code-input>
          </validation-provider>

          <loading-button class="primary" :disabled="invalid" :loading="isLoading">Submit</loading-button>

      </form>

    </validation-observer>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CodeInput from '../CodeInput';
import LoadingButton from '../LoadingButton';
import { mapActions } from 'vuex';

export default {
  name: 'VerificationCodeForm',
  props: {},
  data() {
    return {
      code: null,
      isLoading: false
    }
  },
  components: { ValidationObserver, ValidationProvider, CodeInput, LoadingButton },
  methods: {
    ...mapActions({ 
      saveOneTimeCode: 'oneTimeStore/saveOneTimeCode',
      message: 'messagesStore/message',
      hasNotSentAnEmail: 'oneTimeStore/hasNotSentAnEmail'
    }),

    async submit() {
      this.isLoading = true;
      try {
        const { data } = await this.$api.markCodeAsUsed(this.code);

        this.saveOneTimeCode(data.code);

        this.$router.push({name: "applications"});

        this.message({type: 'success', message: 'Access granted.'});

        this.$emit('onFinish');

      } catch (e) {
        const error = e.response.data.error;

        if ("code_expired" === error) {

          this.message({type: 'error', message: `The code has expired`});

        } else if ("code_already_used" === error) {          

          this.message({type: 'error', message: `The code is already used`});

        } else if ("code_does_not_exist" === error) {

          this.message({type: 'error', message: `Code is invalid, no access was granted`});

        }

      }
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.verification-code-form {
  background-color: $primary-02;
  text-align: center;
  &__form {
    display: grid;
    grid-row-gap: 26px;

  }

  a {
    color: $secondary-03 !important
  }
}
</style>
