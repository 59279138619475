<template>
  <ul class="aplication-list">
    <li
      :id="`component-list-${component.position}`"
      v-for="(component, index) in components"
      :key="index">
      <component
        v-bind:is="component.name"
        :style="{'grid-area': `area-${component.position}`}"
        class="app-card-component"
        :small="component.position != 2"
      >
      </component>
    </li>
  </ul>
</template>

<script>

import ComingSoon from '@/components/ComingSoon';
import AppCard from '@/components/AppCard.vue';

export default {
  name: 'AplicationList',
  props: {},
  components: { ComingSoon, AppCard },
  data() {
    return {
      components: [
        { name: "ComingSoon", position: 1 },

        { name: "AppCard", position: 2 },

        { name: "ComingSoon", position: 3 }
      ],
      positions: [1, 2, 3]
    }
  },
  mounted() {},
  methods: {
    // add -> v-touch:swipe="onSwipe"
    onSwipe(direction) {
      if (direction == 'left') {
        this.left();
      } else if (direction == 'right') {
        this.right();        
      }
    },
    // add -> v-touch="onClick"
    onClick(event) {
      const id = event.target.id;
      const positionText = id.replace('component-list-', "");
      const position = Number(positionText);

      if (isNaN(position)) {
        return;
      }
      if (position == 1) {
        this.right();
      }
      if (position == 3) {
        this.left();
      }
    },
    right() {
      this.rotate();
      this.updatePositions();
    },
    left() {
      this.rotate(true);
      this.updatePositions();
    },
    updatePositions() {
      for (let index = 0; index < 3; index++) {
        this.components[index].position = this.positions[index];        
      }
    },
    rotate(reverse) {
      if (reverse) this.positions.unshift(this.positions.pop());
      else this.positions.push(this.positions.shift());
      return this.positions;
    }
  }
}
</script>

<style lang="scss" scoped>
.aplication-list {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  justify-items: center;
  li {
    position: relative;

    &:first-child > .app-card-component { right: -100% }
    &:last-child > .app-card-component { left: 0 }

    &:first-child, &:last-child {
      .app-card-component {
        position: absolute;
        transform: translateY(70%);
        cursor: pointer;
        &:hover {
          transform: scale(1.1) translateY(65%);
          transition: .5s;
        }
      }
    }
  }

  @include respond-to("large and down") {
    display: block;
    li {
      &:first-child, &:last-child { display: none }
    }
  }
}
</style>
