<template>
  <div class="footer-bar">
    <column-block>
      <p class="footer-bar__content">
        Powered by <a href="https://scrt.network" target="_blank" rel="noopener noreferrer">Secret Network</a> & <a href="https://keytango.io" target="_blank" rel="noopener noreferrer">keyTango</a>
      </p>
    </column-block>
  </div>
</template>

<script>
export default {
  name: 'HeaderBar',
  components: { },
  data() {
    return {
      routes: []
    }
  }
}
</script>

<style lang="scss" scoped>

.footer-bar {
  width: 100%;
  display: grid;
  justify-items: center;
  text-align: center;
  background-color: $primary-02;
  box-sizing: border-box;
  padding: 2rem;
  letter-spacing: 0.25rem;
  a {
    color: $secondary-03;
  }
  @include respond-to("small and down") {
    &__content {
      display: grid;
    }
  }
}
</style>
