<template>
  <div class="liquidity-pool">
      <img :src="require(`../assets/${fromCurrency.toLowerCase()}.svg`)" alt="currency icon">
      <h3>{{fromCurrency}}</h3>
  </div>
</template>

<script>
export default {
  name: 'LiquidityPool',
  props: {
    fromCurrency: {
      type: String
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.liquidity-pool {
  display: grid;
  justify-self: center;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 10px;
  align-items: center;
  img {
    height: 2rem;
    width: 2rem;
  }
  h3 {
    color: $neutral-white;
    font-weight: 400;
  }
}
</style>
