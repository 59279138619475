import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    messages: {},
  },

  // Hate Vuex again.
  mutations: {
    addMessage(state, message) {
      Vue.set(state.messages, message.id, message);
    },
    removeMessage(state, message) {
      Vue.delete(state.messages, message.id);
    },
    removeAllMessages(state) {
      state.messages = {};
    },
  },

  actions: {
    message({commit}, message) {
      message.id = new Date().getTime().toString();
      commit('addMessage', message);
      setTimeout(() => {commit('removeMessage', message);}, 5000);
    },
    hideMessage({commit}, message) {
      commit('removeMessage', message);
    },
    hideAllMessages({commit}) {
      if (this.getters.messages > 0) {
        commit('removeAllMessages');
      }
    }
  }
};
