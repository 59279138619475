<template>
  <div class="notes" :class="{card: isCard, 'primary-09': primary09}">
    <h4>
      <slot name="headline"></slot>
    </h4>
    <p>
      <slot name="body"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Notes',
  props: {
    isCard: {
      type: Boolean
    },
    primary09: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>

.notes {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 4px;
  text-align: start;
  p { line-height: 1.5rem; }
  &.card {
    padding: 1rem;
    background-color: $primary-03;
    border-radius: 1rem;
  }
  &.primary-09 {
    h4 {
      color: $primary-09;
    }
  }
}
</style>
