<template>
  <div class="balances">
    <div class="balance">
      <span>Available to Deposit</span>
      <span v-if="loading"><b>{{ sustBalance }} UST</b></span>
      <span v-else>...</span>
    </div>
    <div class="balance">
      <span>Current Balance</span>
      <span v-if="loading"><b>{{ ustStakingPoolBalance }} UST</b></span>
      <span v-else>...</span>
    </div>
    <div class="balance">
      <span>Claimable Rewards</span>
      <span v-if="loading"><b>{{ rewards }} SEFI</b></span>
      <span v-else>...</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { coinConvert } from '@stakeordie/griptape.js';

export default {
  methods: {
    formatBalance(balance, decimals) {
      if (!balance) return;
      return coinConvert(balance, decimals, 'human', 2);
    }
  },

  computed: {
    ...mapState('sust', {
      rawSustBalance: state => state.balance
    }),
    ...mapState('ustStakingPool', {
      rawUstStakingPoolBalance: state => state.balance,
      rawRewards: state => state.rewards
    }),

    sustBalance() {
      return this.formatBalance(this.rawSustBalance, 18);
    },

    ustStakingPoolBalance() {
      return this.formatBalance(this.rawUstStakingPoolBalance, 18);
    },

    rewards() {
      return this.formatBalance(this.rawRewards, 6);
    },

    loading() {
      return this.rawSustBalance
          && this.rawUstStakingPoolBalance
          && this.rawRewards;
    }
  }
}
</script>

<style lang="scss">
.balances {
  padding: 24px;
  display: grid;
  grid-row-gap: 10px;
}

.balance {
  display: flex;
  justify-content: space-between;
}
</style>
