<template>
  <div class="base-layout" :class="{basic: basic}">
      <header-bar class="base-layout__header"></header-bar>
      <div></div>
      <main class="base-layout__content">
        <column-block>
          <slot></slot>
        </column-block>
      </main>
      <footer-bar></footer-bar>
  </div>
</template>

<script>
import HeaderBar from './HeaderBar.vue'
import FooterBar from './FooterBar.vue'

export default {
  name: 'PublicLayout',
  props: {
    basic: {
      type: Boolean
    }
  },
  components: { HeaderBar, FooterBar }
}
</script>

<style lang="scss" scoped>

.base-layout {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "body" "footer";

  &__header {
    grid-area: header;
  }
  &__content {
    grid-area: body;
    display: grid;

    @include respond-to("large and up") {
      padding-bottom: 136px;
    }
    @include respond-to("medium and down") {
      padding-top: 56px;
      padding-bottom: 56px;
    }

    & a {
      color: $secondary-03;
      text-decoration: underline;
    }
  }
  &__footer {
    grid-area: footer;
    align-self: end;
  }
  &.group {
    .base-layout__content {
      background-image: url('../assets/group.svg');

      background-position: center;
      background-repeat: repeat;
      background-size: 100% 100%;
    }
  }
  &.basic {
    grid-template-rows: auto 1fr;
    .base-layout__footer { display: none }
  }
}
</style>
